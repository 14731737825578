import React, { useState, useEffect, useCallback } from "react";
import { navigate } from "gatsby";
import VisibilitySensor from "react-visibility-sensor";
import Layout from "../components/layout"
import Heading from "../components/solutions/service/Heading";
import Overview from "../components/solutions/service/overview";
import OurApproach from "../components/OurApproach";
import EnquireNow from "../components/EnquireNow";
// import SuccessStories from "../components/SuccessStories"
// import Platforms from "../components/solutions/service/Platforms";
import Process from "../components/solutions/service/Process";
import WhatWeDo from "../components/solutions/service/WhatWeDo";
import Navigation from "../components/solutions/service/Navigation";
import "../styles/service.scss"
import SEO from "../components/seo"
import Partner from "../components/homepage/partner"
import { graphql } from "gatsby"
import Benefits from "../components/solutions/service/Benefits";
const SolutionServices = ({ data }) => {
  const getServiceData = (data) => {
    const values = data.allStrapiSolutionServices.edges[0].node;
    return values;
  }
  const getData = getServiceData(data);
  

  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO slug={getData.slug} locale={getData.locale} title={getData.metaTitle} description={getData.metaDes} />
      <Heading herosection={getData.heroSection} locale={getData.locale} slug={getData.slug} navbar={getData.menuBar} />
      
      <VisibilityWrap slug={getData.slug} sectionId="overview">
        <Overview overview={getData.overview} />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="approach">
        <OurApproach approach={getData.approach} />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="process">
        <Process process={getData.process} />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="benefits">
        <WhatWeDo locale={getData.locale} slug={getData.slug} whatwedo={getData.whatwedo} />
      </VisibilityWrap>

      {/* <EnquireNow data={getData.cloudjourney} /> */}
      {/* <Benefits benifits={getData.benifits} /> */}
      {/* <SuccessStories locale={getData.locale} /> */}
      
      <VisibilityWrap slug={getData.slug} sectionId="partners">
        <Partner title={getData.platform.title} locale={getData.locale} />
      </VisibilityWrap>

    </Layout>
  )
}

export default SolutionServices;

const VisibilityWrap = ({ slug, sectionId, children }) => {

  
  const [active, setActive] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  setTimeout(() => {
      setActive(true);
  }, 1200);

  useEffect(() => {
    const handler = () => {
      setActive(false);
      setTimeout(() => {
          setActive(true);
      }, 1000);
    };

    setScreenHeight(window.innerHeight);
    
    const upperClass = document.getElementsByClassName('service_upper_class')[0];
    
    upperClass.addEventListener("click", handler);
    
    return () => {
      upperClass.removeEventListener("click", handler);
    }
  });

  return (
    <VisibilitySensor
      active={active}
      partialVisibility={true}
      delayedCall={true}
      offset={{
        top: screenHeight > 0 ? screenHeight / 2 : 300,
        bottom: screenHeight > 0 ? screenHeight / 2 : 300
      }}
      onChange={(isVisible) => {
          if(isVisible) {
              navigate(`/${slug}/#${sectionId}`);
          }
      }}
    >
      {children}
    </VisibilitySensor>
  )
}

export const query = (graphql`
query SolutionServices($slug:String!,$locale:String!) { 
  allStrapiSolutionServices(filter: {locale: {eq: $locale}, slug: {eq: $slug}}){
    edges {
        node {
          locale
          slug
          metaTitle
          metaDes
          approach {
            apprch1
            apprch2
            apprch3
            apprch4
            des
            heading
            title
          }
          benifits{
            title
            heading
            services
          }
          cloudjourney {
            btn
            email
            title
          }
          heroSection {
            btn
            des1
            des2
            heading1
            heading2
            title
          }
          menuBar {
            l1
            l2
            l3
            l4
            l5
            l6
            l7
          }
          platform {
            title
          }
          process {
            des1
            des2
            heading
            services
            title
          }
          overview {
            des1
            des2
            heading
            title
          }
          whatwedo {
            bulletpoints {
              des
              title
            }
            headingGlance
            descGlance
            bulletPointsForGlance
            titleMigrate
            headingMigrate
            descMigrate
            bulletPointsForMigrate{
              title
              des
            }
            heading
            heading1
            title
          }
        }
      }  
    }
  }
`)