import React from "react"

const Overview = ({overview}) => {
    return (
        <div className="overview serviceHotspot" id="overview">
            <div className="containerwrap">
                <div className="overviewTitle">{overview.title}</div>
                <h2 className="overviewHeading">{overview.heading}</h2>
                <div className="overviewDescriptionSection">
                    <div className="overviewDescription" dangerouslySetInnerHTML={{ __html: overview.des1 }} />
                    <div className="overviewDescription" dangerouslySetInnerHTML={{ __html: overview.des2 }} />
                </div>
            </div>
        </div>
    )
}

export default Overview;