import React from 'react'
import { useState } from 'react';
import { navigate } from "gatsby";

function Navigation({data, locale, slug}) {
    const [hashLink, setHashLink] = useState("");
    const handleClick = (val) => {
        setHashLink(val);
        navigate(`/${slug}/#${val}`, { replace: true })
    }

    React.useEffect(() => {

        let selectedHashLink = window?.location.href.split('#')[1]
        if(selectedHashLink) {
            setHashLink(selectedHashLink);
        }
    });

    return (
            <div className="pageNav solutionPage">
                <ul className="pageNav-ul">
                <li key={1} onClick={(e) => handleClick("overview")} className={hashLink === "overview" ? 'active':''}>{data.l1}</li>
                    <li key={2} onClick={(e) => handleClick("approach")} className={hashLink === "approach" ? 'active':''}>{data.l2}</li>
                    <li key={3} onClick={(e) => handleClick("process")} className={hashLink === "process" ? 'active':''}>{data.l3}</li>
                    <li key={4} onClick={(e) => handleClick("benefits")} className={hashLink === "benefits" ? 'active':''}>{data.l4}</li>
                    <li key={5} onClick={(e) => handleClick("partners")} className={hashLink === "partners" ? 'active':''}>{data.l6}</li>
                    {/* <li><a href="#overview">{data.l1}</a></li>
                    <li><a href="#approach">{data.l2}</a></li>
                    <li><a href="#process">{data.l3}</a></li>
                    <li><a href="#benefits">{data.l4}</a></li> */}
                    {/* <li><a href="#successstories">{data.l5}</a></li> */}
                    {/* <li><a href="#partners">{data.l6}</a></li> */}
                    {/* <li><a href="#heading">{data.l7}</a></li> */}
                </ul>
            </div>
    )
}


export default Navigation
