import React from "react"


const Process = ({ process }) => {
    return (
        <div className="process serviceHotspot" id="process">
            <div className="containerwrap">
                <div className="prorcss_wrap">
                    <div className="processLeftSection">
                        <div className="processTitle">{process.title}</div>
                        <div className="processHeading">{process.heading}</div>
                        <div className="processDescription">{process.des1}</div>
                        <div className="processDescription">{process.des2}</div>
                    </div>
                    <div className="processRightSection">
                        <div className="inner_process">
                            <div className="inner_process_left">
                                {process.services.map((process, index) =>
                                    index <= 2 &&

                                    <div className="processDetails" key={index}>
                                        <div className="number">0{index + 1}</div>
                                        <div className="process_content">{process}</div>
                                    </div>

                                )}
                            </div>
                            <div className="inner_process_right">
                                {process.services.map((process, index) =>
                                    index > 2 &&

                                    <div className="processDetails" key={index}>
                                        <div className="number">0{index + 1}</div>
                                        <div className="process_content">{process}</div>
                                    </div>

                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Process;