import React from "react";
import Devops from "../../../images/assets/devop.svg";
import Private from "../../../images/assets/private.svg";
import Certifide from "../../../images/assets/certifide.svg";
import Project from "../../../images/assets/project-ma.svg";
import App from "../../../images/assets/app.svg";
import Awsmig from "../../../images/assets/aws-mig.svg";
import MigrationCampaign from "../../homepage/migration_campaign";
import OptCampaign from "../../homepage/opt_campaign";
import { useStaticQuery, graphql } from "gatsby";
import "../../../styles/newTechnologies.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function WhatWeDo({ whatwedo, slug, locale }) {
  const queryData = useStaticQuery(graphql`
    query {
      allStrapiIndexpage {
        edges {
          node {
            locale
            herosection {
              herobutton
            }
            campaigns {
              title
              heading1
              heading2
              description
              sections {
                heading
                sn
                title
                info
                tabs
                tab1 {
                  opt1
                  opt1description
                  opt2
                  opt2description
                  opt3
                  opt3description
                  pillars {
                    name
                    options
                  }
                }
                tab2 {
                  opt1
                  opt2
                  opt3
                  opt4
                  opt5
                  opt6
                  opt7
                  opt8
                }
                tab3 {
                  opt3
                  opt4
                  opt5
                  opt5description
                  opt4description
                  opt3description
                  opt2description
                  opt1description
                  opt1
                  opt2
                }
              }
            }
          }
        }
      }
    }
  `).allStrapiIndexpage.edges;
  const data = queryData.find((node) => node.node.locale === locale)?.node;
  console.log(data);
  return (
    <div className="why_service serviceHotspot" id="benefits">
      <div className="containerwrap">
        <div className="services">
          <div className="servicesTitle">{whatwedo.title}</div>
          <div className="servicesHeading">{whatwedo.heading}</div>
          <div className="servicesHeading">{whatwedo.heading1}</div>
          <div className="ServicesFilterOptions">
            <div className="ServicesFilterOption">
              {/* <img src={search} alt="service" className="ServicesFilterOptionImg" /> */}
              <Devops />
              <div className="ServicesFilterOptionName">
                {whatwedo.bulletpoints[0].title}
              </div>
              <div
                className="ServicesFilterOptionDescription"
                dangerouslySetInnerHTML={{
                  __html: whatwedo.bulletpoints[0].des,
                }}
              />
            </div>
            <div className="ServicesFilterOption">
              {/* <img src={search} alt="service" className="ServicesFilterOptionImg" /> */}
              <Private />

              <div className="ServicesFilterOptionName">
                {whatwedo.bulletpoints[1].title}
              </div>
              <div
                className="ServicesFilterOptionDescription"
                dangerouslySetInnerHTML={{
                  __html: whatwedo.bulletpoints[1].des,
                }}
              />
            </div>
            <div className="ServicesFilterOption">
              {/* <img src={search} alt="service" className="ServicesFilterOptionImg" /> */}
              <Certifide />

              <div className="ServicesFilterOptionName">
                {whatwedo.bulletpoints[2].title}
              </div>
              <div
                className="ServicesFilterOptionDescription"
                dangerouslySetInnerHTML={{
                  __html: whatwedo.bulletpoints[2].des,
                }}
              />
            </div>
            <div className="ServicesFilterOption">
              {/* <img src={search} alt="service" className="ServicesFilterOptionImg" /> */}
              <Project />

              <div className="ServicesFilterOptionName">
                {whatwedo.bulletpoints[3].title}
              </div>
              <div
                className="ServicesFilterOptionDescription"
                dangerouslySetInnerHTML={{
                  __html: whatwedo.bulletpoints[3].des,
                }}
              />
            </div>
          </div>
        </div>

        {whatwedo.titleMigrate && (
          <div className="partnerSecTechnologies pt-0">
            <div className="partSecwrapTechnologies pb-0">
              <div className="partsecleftTechnologies partseccover">
                <div className="problem-technologies serviceHotspot">
                  <div className="overviewTitle">{whatwedo.titleMigrate}</div>
                  <div
                    className="overviewHeading"
                    dangerouslySetInnerHTML={{
                      __html: whatwedo.headingMigrate,
                    }}
                  />

                  <div className="overviewDescriptionSection mt-3">
                    <div
                      className="overviewDescriptionTechnologies"
                      dangerouslySetInnerHTML={{ __html: whatwedo.descMigrate }}
                    />
                  </div>
                  <Slider className="partner-slider">
                    {whatwedo.bulletPointsForMigrate.map(
                      ({ title, des }, index) => (
                        <div key={index} className="partsecleft">
                          <div className="leftSection d-md-flex flex-md-column align-items-md-center w-100">
                            <div className="secHeading text-center mb-2">
                              {title}
                            </div>
                            <p
                              className="text-center"
                              dangerouslySetInnerHTML={{ __html: des }}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        )}

        {slug === "cloud-migration-services" && (
          <MigrationCampaign
            service={true}
            btntext={data.herosection.herobutton}
            section={data.campaigns.sections[1]}
          />
        )}
        {slug === "cloud-optimization-services" && (
          <OptCampaign
            service={true}
            btntext={data.herosection.herobutton}
            section={data.campaigns.sections[0]}
          />
        )}
      </div>
      {whatwedo.headingGlance && (
        <div className="processOpsioOfferings serviceHotspot pb-0 pt-0">
          <div className="containerwrap">
            <div className="prorcss_wrap w-100">
              <div className="ourApproachWhiteOfferings w-100 serviceHotspot pt-0 mb-0">
                <div className="containerwrap">
                  <div
                    className="approchHeading"
                    dangerouslySetInnerHTML={{ __html: whatwedo.headingGlance }}
                  />
                  <div className="approchdescription">
                    {whatwedo.descGlance}
                  </div>
                </div>
              </div>
              <div className="d-flex mt-5">
                <div className="processRightSectionServices w-100">
                  <div className="inner_process d-md-flex justify-content-md-center w-100">
                    <div className="inner_process_left d-md-flex justify-content-md-center  w-100 col-md-12 col-12 flex-md-wrap">
                      {whatwedo.bulletPointsForGlance.map(
                        (bulletPoint, index) => (
                          <div
                            className={`processDetails col-md-4 col-12  ${
                              index === 2 || index === 5 || index === 8
                                ? "me-md-0 pe-md-0"
                                : ""
                            }`}
                            key={index}
                          >
                            <div className="number">
                              {index < 9 ? `0${index + 1}` : `${index + 1}`}
                            </div>
                            <div className="process_content_solution_purple">
                              {bulletPoint}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WhatWeDo;
